import React, { useEffect, useState } from 'react';
import './Footer.css';
import { isCustomFooterMenusEnabled } from '../../hooks/helpers';
import { Link, NavLink } from 'react-router-dom';
const USE_FOOTER_MENUS = isCustomFooterMenusEnabled()
const COPYRIGHT_TEXT = process.env.REACT_APP_IBL_FOOTER_COPYRIGHT
const COPYRIGHT_LINK = process.env.REACT_APP_IBL_FOOTER_COPYRIGHT_LINK
const Footer = (props) => {
  const [customMenus, setCustomMenus] = useState([])

  const handleFooterCustomMenus = () => {
    const menuInString = process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_FOOTER_MENUS;
    const firstLevelMenus = String(menuInString).split('|');
    if (firstLevelMenus.length > 0) {
      const menusList = [];
      firstLevelMenus.forEach((menus) => {
        const menu = String(menus).split(':');
        const menuLink = menu.filter((pre, index)=>index!==0).join(":")
        menusList.push({
          label: menu[0],
          link: menuLink
        });
      });
      setCustomMenus(menusList);
    }
  }

  useEffect(() => {
    USE_FOOTER_MENUS && handleFooterCustomMenus()
  }, []);
  return (
    <div className={`sk-footer ${process.env.REACT_APP_IBL_ENABLE_MENTOR ? "mentor-modal-available" : ""}`}>
      <div className="div-block-158" style={{justifyContent:`${USE_FOOTER_MENUS ? "space-between" : "flex-start" }`}}>
        {
          process.env.REACT_APP_BOTTOM_LOGO_URL && 
          <Link
            className="skills-logo w-inline-block w--current"
            to={process.env.REACT_APP_IBL_LOGO_LINK ?? '/'}
          >
            <img
              alt=""
              className="image"
              loading="lazy"
              src={process.env.REACT_APP_BOTTOM_LOGO_URL}
              style={{
                height: process.env.REACT_APP_IBL_LOGO_HEIGHT || '57px',
              }}
            />
          </Link>
        }
        {
          USE_FOOTER_MENUS && (
            <div className={"footer-menu-container"}>
              {
                customMenus.map((menu)=>(
                  <Link target={String(menu.link).includes("http") ? "_blank" : null} to={menu.link}>{menu.label}</Link>
                ))
              }
            </div>
          )
        }
        {
          !!COPYRIGHT_LINK ?
            <Link target={"_blank"} to={!!COPYRIGHT_LINK ? COPYRIGHT_LINK : "#"} className="text-block-102 copyright-box">{COPYRIGHT_TEXT}</Link>
            : <div className="text-block-102 copyright-box">{COPYRIGHT_TEXT}</div>
        }
      </div>
    </div>
  );
};

export default Footer;
