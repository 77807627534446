import Contentfilterbox from '../../components/Contentfilterbox/Contentfilterbox';
import React, { useEffect, useState } from 'react';

import Coursesfilterbox from '../../components/Coursesfilterbox';
import ReactPaginate from 'react-paginate';
import { api, getUserName, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import {
  filterFacetsKeys,
  getDiscoverItemsAPI,
  getFacetItemsAPI,
  getQueryParamValue,
  getTenants,
} from '../../hooks/helpers';
import useDiscover from '../../hooks/useDiscover';
import FilterTags from '../../components/FilterTags/FilterTags';
import { useTranslation } from 'react-i18next';

const BrowseContentView = () => {
  const [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
    totalPages,
    setTotalPages,
  ] = useDiscover();
  const [currentPage, setCurrentPage] = useState(1);
  const [contentFilterBoxItems, setContentFilterBoxItems] = useState({});
  const { t } = useTranslation();

  const FACETS_TO_HIDE =
    process.env.REACT_APP_IBL_DISCOVER_FACETS_FILTERS_TO_HIDE;

  useEffect(() => {
    getFacetItemsAPI((facets) => {
      setContentFilterBoxItems(
        FACETS_TO_HIDE ? filterFacetsKeys(facets, FACETS_TO_HIDE) : facets
      );
    });
  }, []);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  const paginate = ({ selected }) => {
    const requestData = getFacetRequestObject(selectedTerms);
    const newPage = selected + 1;
    setCurrentPage(newPage);

    requestData['limit'] = 12;
    if (selected > 0) {
      requestData['offset'] = selected * 12;
    }
    const searchQueryValue = getQueryParamValue();
    if (searchQueryValue) {
      requestData['query'] = searchQueryValue;
    }

    const username = getUserName();
    setDiscoverLoaded(false);

    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        const calculatedPages =
          _data.total_pages || Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    } else {
      api.ibldmsearch.searchCatalog(requestData, (_data) => {
        const calculatedPages =
          _data.total_pages || Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    }
  };

  const extractCourses = (results) => {
    return results.map((result) => ({ ...result.data, type: result.type }));
  };

  const getFacetRequestObject = (_selectedTerms) => {
    const requestData = {};
    (_selectedTerms || []).forEach((_term) => {
      let [term, value] = _term.split(':');
      if (term === 'providers') {
        term = 'tenant';
      }
      if (term === 'format') {
        term = 'courseformat';
      }
      if (term === 'course duration') {
        term = 'duration';
      }
      if (requestData.hasOwnProperty(term)) {
        if (Array.isArray(requestData[term])) {
          requestData[term].push(value);
        } else {
          requestData[term] = [requestData[term], value];
        }
      } else {
        requestData[term] = value;
      }
    });
    return requestData;
  };

  const facetHandler = (newSelectedTerms) => {
    const requestData = getFacetRequestObject(newSelectedTerms);
    setCurrentPage(1);
    requestData['limit'] = 12;
    const username = getUserName();
    setDiscoverLoaded(false);
    const searchQueryValue = getQueryParamValue();
    if (searchQueryValue) {
      requestData['query'] = searchQueryValue;
    }
    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        const calculatedPages = Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    } else {
      api.ibldmsearch.searchCatalog({ ...requestData }, (_data) => {
        const calculatedPages = Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    }
  };

  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left">
                    <div className="prof_box browse">
                      <Contentfilterbox
                        discoverItems={contentFilterBoxItems}
                        selectedTerms={selectedTerms}
                        setSelectedTerms={setSelectedTerms}
                        facetHandler={facetHandler}
                        setDiscoverLoaded={setDiscoverLoaded}
                        isLoading={!discoverLoaded}
                      />
                    </div>

                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>
                  <div className="br_panel" data-name="top">
                    <div className="text-block-308">
                      {t('Featured Learning Content')}
                    </div>
                    {!discoverLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'300px'} />
                    ) : (
                      <>
                        <FilterTags
                          selectedTerms={selectedTerms}
                          setSelectedTerms={setSelectedTerms}
                          facetHandler={facetHandler}
                          setDiscoverLoaded={setDiscoverLoaded}
                        />
                        <div className="div-block-430 sl pathway_grid">
                          <Coursesfilterbox
                            discoverItems={discoverItems}
                            discoverLoaded={discoverLoaded}
                          />
                        </div>
                        {discoverLoaded && totalPages > 1 && (
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={totalPages}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination m-t-20'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                            forcePage={currentPage - 1}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default BrowseContentView;
